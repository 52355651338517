import { graphql } from 'gatsby';
import { FormattedMessage, injectIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import React from 'react';
import Layout from '../components/layout';
import News from '../components/news';
import SEO from '../components/seo';

const NewsPage = ({ intl, data }) => {
  const { allMarkdownRemark: { edges } } = data;

  const news = [];
  edges.forEach((n, index) => news.push(
    <News data={n.node} index={index} key={n.node.id} />,
  ));

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: 'news.title' })} />
      <div className="container section">
        <h1 className="title is-4">
          <FormattedMessage id="news.title" />
        </h1>
        {news}
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] },
      filter: { fileAbsolutePath: {regex: "/news/.*\\.md$/"} }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            date
            title
            image {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

NewsPage.propTypes = {
  intl: PropTypes.object,
  data: PropTypes.object,
};

export default injectIntl(NewsPage);
