import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';
import { fromNow } from '../utils/js/date';
import GoogleAdsense from './google-adsense';
import newsStyles from './news.module.scss';

const News = ({ data, index }) => {
  let classes = newsStyles.body;
  if (index % 2) classes += ` ${newsStyles.pair}`;
  switch (index % 3) {
    case 1: classes += ` ${newsStyles.isDanger}`;
      break;
    case 2: classes += ` ${newsStyles.isWarning}`;
      break;
    default:
      classes += ` ${newsStyles.isInfo}`;
  }

  const textColumn = (
    <div className="column is-8">
      <div className="level m-b-xs p-l-sm p-r-sm">
        <div className="level-left">
          <div className="level-item">
            <h3 className="title is-5">{data.frontmatter.title}</h3>
          </div>
        </div>
        <div className="level-right">
          <div className="level-item is-italic">
            {fromNow(data.frontmatter.date)}
          </div>
        </div>
      </div>

      <div
        className={classes}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: data.html }}
      />
    </div>
  );

  const imageColumn = (
    <div className="column is-4 p-xl">
      { data.frontmatter.image
        ? <Img fluid={data.frontmatter.image.childImageSharp.fluid} /> : (
          <GoogleAdsense
              style={{ display: 'block' }}
              client="ca-pub-1569243764016253"
              slot="6913582508"
              format="auto"
              responsive="true"
          />
        )}
    </div>
  );

  return (
    <div className={newsStyles.container}>
      <div className="columns is-vcentered content">
        { index % 2 ? imageColumn : textColumn }
        { index % 2 ? textColumn : imageColumn }
      </div>
    </div>
  );
};

News.propTypes = {
  data: PropTypes.object,
  index: PropTypes.number,
};

News.defaultProps = {
};

export default News;
